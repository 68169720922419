<template>
  <div id="app">
      <b-navbar style="font-family: 'Pirata One', cursive;" fixed-top shadow>
        <template #brand>
          <b-navbar-item tag="router-link" :to="{name:'index'}">
            <img
                src="/static/logo.png"
                style="width: 2em; height: 2em; max-height: 2em;"
            >
            <p style="margin-left: .5em; font-size: 2em;" CLASS="title-new">The Picaroons</p>
          </b-navbar-item>
        </template>
        <template #start>

        </template>

        <template #end>

          <b-navbar-item href="https://opensea.io/collection/the-picaroons" tag="a">
            Marketplace
          </b-navbar-item>

          <b-navbar-item href="https://log.thepicaroons.com" tag="a">
            Log Book
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{name:'leaderboard'}">
            Leaderboard
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{name:'mint'}">
            Mint
          </b-navbar-item>

          <b-navbar-item tag="router-link" :to="{name:'manifest'}">
            <b-button icon-left="package-variant" class="is-primary">My Manifest</b-button>
          </b-navbar-item>

          <b-navbar-dropdown :label="user.address" v-if="user">
            <b-navbar-item tag="router-link" :to="{name:'manifest'}">
              <b-icon icon="package-variant"/>
              <div style="padding-left: 1em;">My Manifest</div>
            </b-navbar-item>
            <b-navbar-item @click="logout">
              <b-icon icon="logout-variant"/>
              <div style="padding-left: 1em;">Logout</div>
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item v-else>
            <b-button @click="login" icon-left="login-variant">Login</b-button>
          </b-navbar-item>

<!--          <b-navbar-item href="https://discord.gg/vEMSd5EAbV" target="_blank" rel="noopener noreferrer">-->
<!--            <b-icon icon="discord"/>-->
<!--            <span class="is-hidden-desktop" style="padding-left: 1em;">Discord</span>-->
<!--          </b-navbar-item>-->

<!--          <b-navbar-item href="https://opensea.io/collection/the-picaroons" target="_blank" rel="noopener noreferrer">-->
<!--            <img src="openseas_logo.svg" style="height: 1em; width: 1em;">-->
<!--            <span class="is-hidden-desktop" style="padding-left: 0.5em">-->
<!--            Openseas-->
<!--            </span>-->
<!--          </b-navbar-item>-->
<!--          <b-navbar-item href="https://twitter.com/The_Picaroons" target="_blank" rel="noopener noreferrer">-->
<!--            <b-icon icon="twitter"/>-->
<!--            <span class="is-hidden-desktop" style="padding-left: 1em;">Twitter</span>-->
<!--          </b-navbar-item>-->
        </template>
      </b-navbar>

    <router-view @login="login" style="margin-top: 1em;"/>
  </div>
</template>

<script>
import {session} from "@/main";
import { SnackbarProgrammatic as Snackbar } from 'buefy'

export default {
  name: 'App',
  computed: {
    user () {
      return this.$root.user
    }
  },
  created() {
    this.getLoggedInUser()
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$root.user = false
    },
    async login() {
      let accounts

      let ethereum;

      try {
          ethereum = window.ethereum
          accounts = await ethereum.request({method: 'eth_requestAccounts'});
      } catch (e) {
        Snackbar.open('Web3 enabled browser or Metamask is required to login.')
        return
      }

      let publicKey = accounts[0]

      let timestamp = new Date().getTime()

      let message = `Picaroons: Login as ${publicKey}.\nUnique code: ${timestamp}`

      let signature = await ethereum.request({
        method: 'personal_sign',
        params: [message, publicKey],
      })

      this.$axios.post('/auth/login', {"address": publicKey, timestamp, signature})
          .then(({data}) => {
            const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000;
            localStorage.setItem('jwt', data.jwt);
            localStorage.setItem('expirationTime', expirationTime.toString());


            this.$root.user = data.user
            console.log(session)

          })
          .catch(error => {
            console.log(error);
            Snackbar.open(error)
          });

    },
    getLoggedInUser () {
      this.$axios.get('auth/me')
          .then(({data}) => {
            this.$root.user = data
          }).catch(err => {
              console.error(err)
              this.$root.user = false
          })
    }
  }
}
</script>