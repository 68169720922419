import Vue from 'vue';
import VueRouter from 'vue-router';

function load (page) {
	return async () => import(`./pages/${page}.vue`)
}

Vue.use(VueRouter);

const routes = [
  // Define your routes here
  // Example:
  { path: '/', component: load('inventory'), name: "manifest", meta:{ requiresAuth: true}},
  { path: '/mint', component: load('mint'), name:  "mint" },
  { path: '/login', component: load('login'), name: "login" },
  { path: '/ships/leaderboard', component: load('ships/leaderboard'), name: "leaderboard" },
  { path: '/ships/:id', component: load('ships/detail'), props: true, name: "shipDetail" },
  {
    path: '/ships/:id/transfer', component: load('ships/transfer'),
    props: true, name: "shipTransfer", meta:{ requiresAuth: true}
  },
  {
    path: '/ships/:id/edit', component: load('ships/edit'),
    props: true, meta:{ requiresAuth: true}, name:"shipEdit"
  },
  { path: '/parts/:id', component: load('partDetail'), props: true, name: "partsDetail" },
  {
    path: '/:pathMatch(.*)*', // catch-all route
    name: 'NotFound',
    component: load('notFound')
  }
];

const router = new VueRouter({
  mode: 'history', // Use 'history' mode for clean URLs (optional)
  routes,
});


const isAuthenticated = () => {
  return localStorage.getItem('jwt') !== null;
};


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated()) {
    // Redirects to login page.
    next({ name: 'login' });
  } else {
    next();
  }
})



export default router;