import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from 'axios';
import router from "@/router";
import Buefy from "buefy";
import 'buefy/dist/buefy.css'

export let session = {
  user: null
}

// axios.defaults.baseURL = 'http://localhost:5001/picaroons-378012/europe-west2/api/';
axios.defaults.baseURL = 'https://europe-west2-picaroons-378012.cloudfunctions.net/api/';

axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('jwt');
    const expirationTime = localStorage.getItem('expirationTime');

    // Check if token has expired
    if (new Date().getTime() > Number(expirationTime)) {
      // The token has expired - remove it from local storage and possibly redirect the user to login page
      localStorage.removeItem('jwt');
      localStorage.removeItem('expirationTime');
    } else if (token) {
      // Token is not expired - attach it to the header
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
  },
  error => {
    Promise.reject(error)
});


// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('jwt');
      localStorage.removeItem('expirationTime');
    }
    return Promise.reject(error);
  }
);



Vue.use(Buefy)

Vue.prototype.$axios = axios;
Vue.prototype.$user = null

new Vue({
  router,
  render: h => h(App),
  data: {
    user: session.user
  }
}).$mount('#app')


export const axiosInstance = axios

